<template>
  <div id="forgetPassword">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('common.button.forgotPw') }}</h2>
          <p></p>
        </div>
        <div class="meta_box" v-if="formFlag">
          <div class="forgetPassword_box">
            <p class="tip">{{ $t('forgetPwReq.tip') }}</p>
            <div class="change_form">
              <el-form :model="emailForm" @submit.native.prevent ref="emailForm" :rules="rule">
                <InputForm
                  :label="$t('common.field.loginEmail')"
                  name="email"
                  v-model.trim="emailForm.email"
                ></InputForm>
                <div class="btn_item">
                  <el-button class="purple_button" @click="submitForm('emailForm')" data-testid="submit">
                    {{ $t('common.button.submit') }}
                  </el-button>
                </div>
              </el-form>
              <p class="error" v-if="errorFlag">{{ errMsg }}</p>
            </div>
          </div>
        </div>
        <Result v-else>
          <p v-html="$t('forgetPwReq.succ')"></p>
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import { apiReq_reset_profile_password } from '@/resource';
import Result from '@/components/Result';

export default {
  name: 'forgetPassword',
  components: { Result },
  data() {
    return {
      formFlag: true,
      emailForm: {
        email: ''
      },
      rule: {
        email: [
          {
            required: true,
            message: this.$t('forgetPwReq.formValidation.emailReq'),
            trigger: 'blur'
          }
        ]
      },
      errMsg: '',
      errorFlag: false
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.getApiReq_reset_profile_password();
        else return false;
      });
    },
    getApiReq_reset_profile_password() {
      this.errMsg = '';
      this.errorFlag = false;

      apiReq_reset_profile_password({
        email: this.emailForm.email,
        baseUrl: location.protocol + '//' + location.hostname
      }).then(resp => {
        if (resp.data.code === 0) {
          if (resp.data.data.result == true) {
            this.formFlag = false;
          }
        } else if (resp.data.code === 421) {
          this.errMsg = resp.data.errmsg;
          this.errorFlag = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/forgetPassword.scss';
</style>
